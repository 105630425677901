import React from "react";
import './InteractiveMenu.css';

// Components
import InteractiveMenuItem from "../InteractiveMenuItem/InteractiveMenuItem";
// import OrderButton from "../OrderButton/OrderButton";
import Page from "../Page/Page";

const InteractiveMenu = ({name, items, currencySymbol, onLoadProgress, onLoadSuccess}) => {
  React.useEffect(() => {
    if (!onLoadProgress || !onLoadSuccess) return;
    setTimeout(() => onLoadProgress(25), 150);
    setTimeout(() => onLoadProgress(50), 300);
    setTimeout(() => onLoadProgress(75), 450);
    setTimeout(onLoadSuccess, 600);
    // eslint-disable-next-line
  }, []);

  const renderItems = () => {
    const itemElements = [];
    items?.forEach((item, index) => {
      if (index === 0 || item['Category'] !== items[index-1]['Category']) {
        itemElements.push(
          <h3 key={item['Category']} className="interactive-menu-category">{item['Category'] || "Category"}</h3>
        );
      }
      itemElements.push(
        <InteractiveMenuItem
          key={item['Id']}
          item={item}
          currencySymbol={currencySymbol}
        />
      );
    })
    return itemElements;
  }

  return (
    <Page>
      <h2 className="interactive-menu-name">{name || 'Menu Name'}</h2>
      { renderItems() }
      {/* <OrderButton /> */}
    </Page>
  );
}

export default InteractiveMenu;
