import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './settingsState.js';

/* Reducer */
import settingsReducer from './settingsReducer';

const SettingsContext = createContext();

export const SettingsProvider = (props) => {
  const [settingsState, settingsDispatch] = useReducer(settingsReducer, initialState);

  return (
    <SettingsContext.Provider value={{settingsState, settingsDispatch}}>
      {props.children}
    </SettingsContext.Provider>
  )
}

export const useSettingsContext = () => useContext(SettingsContext);
