import './PaymentCancel.css';
import React from 'react';

// Contexts
import { useLoadingScreenContext } from '../../contexts/loadingScreen/loadingScreenContext';

// Components
import Page from '../../components/Page/Page';

const PaymentCancel = () => {
  const { loadingScreenDispatch } = useLoadingScreenContext();
  
  React.useEffect(() => {
    loadingScreenDispatch({type: 'SET_PROGRESS', payload: 100});
    loadingScreenDispatch({type: 'SET_VISIBILITY', payload: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className="payment-cancel">
      <h2>Payment Cancelled</h2>
      <p>Press top left logo to go back to menu.</p>
    </Page>
  );
}

export default PaymentCancel;
