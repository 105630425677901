import './BottomNav.css';

// Libraries
import { Link } from 'react-router-dom';

// Contexts
import { useUiStateContext } from '../../contexts/uiState/uiStateContext';

const BottomNav = () => {
  const { uiStateDispatch } = useUiStateContext();

  const handleToggleActionPanel = () => {
    uiStateDispatch({type: 'TOGGLE_ACTION_PANEL'});
  }

  return (
    <div className="bottom-nav">
      <Link className="bottom-nav-item" to="/">
        <i className="fas fa-file-alt" /><p>Menu</p>
      </Link>
      <Link className="bottom-nav-item" to="order">
        <i className="fas fa-concierge-bell" /><p>Order</p>
      </Link>
      <Link className="bottom-nav-item" to="checkout">
        <i className="fas fa-credit-card" /><p>Checkout</p>
      </Link>
      <div className="bottom-nav-item" onClick={handleToggleActionPanel}>
        <i className="fas fa-bars" /><p>Actions</p>
      </div>
    </div>
  );
}

export default BottomNav;
