/* Reducers */
import {
  toggleSettingsPanel,
  toggleActionPanel,
} from './uiStateReducers.js';

const uiStateReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "TOGGLE_SETTINGS_PANEL":
      toggleSettingsPanel(newState);
      break;
    case "TOGGLE_ACTION_PANEL":
      toggleActionPanel(newState);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default uiStateReducer;
