import './ActionItem.css';

// Libraries
import { motion } from 'framer-motion';

const ActionItem = ({actionHandler, iconName, actionName}) => {
  return (
    <div className="action-item" onClick={actionHandler}>
      <motion.div
        className="action-button"
        whileTap={{scale: 1.2}}
      >
        <i className={`fas fa-${iconName || 'utensils'}`} />
      </motion.div>
      <p>{actionName || 'Action'}</p>
    </div>
  );
}

export default ActionItem;
