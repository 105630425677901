import React from 'react';
import './PDFMenu.css';

// Libraries
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const PDFMenu = ({menuContainer, file, onLoadProgress, onLoadSuccess}) => {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [numPages, setNumPages] = React.useState(1);

  React.useEffect(() => {  // TODO: Remove event listener when running multiple times.
    window.addEventListener('resize', () => {
      setContainerWidth(menuContainer.current.clientWidth);
    });
  }, [menuContainer]);

  // Returns a percentage progress to parent.
  const handleLoadProgress = (progress) => {
    onLoadProgress(progress.loaded / progress.total * 100);
  }
  
  const handleLoadSuccess = (pdf) => {
    // Set PDF parameters.
    setContainerWidth(menuContainer.current.offsetWidth);
    setTimeout(() => {
      // Set width again in case the first time caused a scrollbar to appear.
      setContainerWidth(menuContainer.current.offsetWidth);
    }, 50);
    setNumPages(pdf.numPages);

    // Indicate success to parent via onLoadSuccess callback.
    onLoadSuccess();
  }

  const renderPDFPages = () => {
    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(
        <Page
          key={i}
          className="pdf-menu-page"
          pageIndex={i}
          width={containerWidth - 32}
        />
      );
    }
    return pages;
  }

  return (
    <Document
      className="pdf-menu"
      file={file}
      onLoadProgress={handleLoadProgress}
      onLoadSuccess={handleLoadSuccess}
    >
      { renderPDFPages() }
    </Document>
  );
}

export default PDFMenu;
