export const setRestaurantId = (newState, payload) => {
  newState.restaurantId = payload;
}

export const setSessionId = (newState, payload) => {
  newState.sessionId = payload;
}

export const setCustomerId = (newState, payload) => {
  newState.customerId = payload;
}
