import './Checkout.css';
import React from 'react';

// Libraries
import axios from 'axios';
// import ReactGA from 'react-ga';

// Contexts
import { useIdentificationContext } from '../../contexts/identification/identificationContext';
import { useMenuContext } from '../../contexts/menu/menuContext';

// Components
import Bill from '../../components/Bill/Bill';
import Page from '../../components/Page/Page';
import PaymentOptions from '../../components/PaymentOptions/PaymentOptions';
import Survey from '../../components/Survey/Survey';

const Checkout = () => {
  const { identificationState } = useIdentificationContext();
  const { menuState } = useMenuContext();
  const [customerOrders, setCustomerOrders] = React.useState({});
  const [paymentOption, setPaymentOption] = React.useState('stripe');
  const [showSurvey, setShowSurvey] = React.useState(false);
  const menu = menuState.menu.payload?.items || [];

  // Retrieve individual and table bills upon each mount
  React.useEffect(() => {
    const getBill = async () => {
      let orderList = [];
      try {
        const response = await axios.get(
          `https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/Customer/Orders/${identificationState.sessionId}`
        );
        orderList = response.data || [];
      }
      catch (e) {
        console.log(e);
      }

      const customerOrders = {};
      orderList.forEach(item => {
        const customerId = item['CustomerId'];
        if (!customerOrders[customerId]) {
          customerOrders[customerId] = [];
        }
        const food = menu.find(e => e['Id'] === item['FoodId']);
        customerOrders[customerId].push({
          id: food['Id'],
          name: food['Name'],
          price: food['Price'],
          quantity: item['Quantity'],
        });
      });

      setCustomerOrders(customerOrders);
    }
    getBill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderBills = () => {
    const bills = [];
    Object.keys(customerOrders).forEach((customerId, index) => {
      bills.push(
        <Bill
          key={index}
          customerId={customerId}
          items={customerOrders[customerId]}
          paymentOption={paymentOption}
        />
      );
    });
    return bills;
  }

  const handlePaymentOption = (optionId) => {
    setPaymentOption(optionId);
  }

  // const handlePay = (group) => {
  //   ReactGA.event({
  //     category: 'UI',
  //     action: `Checkout ${group}`,
  //     // value: customerBill,
  //   });

  //   switch (group) {
  //     case 'self':
  //       window.open('https://buy.stripe.com/test_14k147eD847h3qE7st');
  //       break;
  //     case 'all':
  //       window.open('https://buy.stripe.com/test_6oE8wz8eKcDN7GUfZ0');
  //       break;
  //     default:
  //       break;
  //   }

  //   setShowSurvey(true);
  // }

  return (
    <Page className="checkout-page">
      <h2 className="checkout-page-title">Checkout</h2>
      {/* <div className="checkout-page-bills">
        <p>Your bill: ${customerBill}</p>
        <p>Your table's total bill: ${tableBill}</p>
      </div> */}
      <PaymentOptions onSelect={handlePaymentOption} />
      { renderBills() }
      {/* <div className="checkout-buttons">
        <div
          className="checkout-button"
          onClick={() => handlePay('self')}
        >Pay for Self</div>
        <div
          className="checkout-button"
          onClick={() => handlePay('all')}
        >Pay for All</div>
      </div> */}
      <Survey show={showSurvey} onFinish={() => setShowSurvey(false)} />
    </Page>
  );
}

export default Checkout;
