import './Alerts.css';
import React from 'react';

// Libraries
import { AnimatePresence, motion } from 'framer-motion';

// Contexts
import { useAlertsContext } from '../../contexts/alerts/alertsContext';

const Alerts = () => {
  const {alertsState, alertsDispatch} = useAlertsContext();

  React.useEffect(() => {
    alertsState.alertsQueue.forEach(alert => {
      const id = alert.id;
      alertsDispatch({type: 'DEQUEUE_ALERT', payload: id});
      alertsDispatch({type: 'ADD_ALERT', payload: alert});
      setTimeout(() => {
        alertsDispatch({type: 'REMOVE_ALERT', payload: id});
      }, 1000*alertsState.alertTimer);
    });
  });

  const removeAlert = (id) => {
    alertsDispatch({type: 'REMOVE_ALERT', payload: id});
  }

  const renderAlerts = () => {
    const alerts = [];
    alertsState?.alerts?.forEach(e => {
      alerts.push(
        <motion.div
          className="alert"
          key={e.id}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0, transitionEnd: { display: 'none' } }}
          transition={{ duration: 0.3 }}
          onClick={() => {removeAlert(e.id)}}
        >
          {e.value}
        </motion.div>
      );
    });
    return alerts;
  }

  return (
    <div className="alerts">
      <AnimatePresence>
        { renderAlerts() }
      </AnimatePresence>
    </div>
  );
}

export default Alerts;
