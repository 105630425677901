import React from 'react';
import './App.css';

// Libraries
import axios from 'axios';
import {
  Routes,
  Route
} from 'react-router-dom';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { v4 as uuidv4 } from 'uuid';

// Contexts
import { useIdentificationContext } from './contexts/identification/identificationContext';

// Routes
import Checkout from './routes/Checkout/Checkout';
import Order from './routes/Order/Order';
import PaymentCancel from './routes/PaymentCancel/PaymentCancel';
import PaymentSuccess from './routes/PaymentSuccess/PaymentSuccess';

// Components
import ActionPanel from './components/ActionPanel/ActionPanel';
import Alerts from './components/Alerts/Alerts';
import BottomNav from './components/BottomNav/BottomNav';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import Menu from './components/Menu/Menu';
import Navbar from './components/Navbar/Navbar';
import SettingsPanel from './components/SettingsPanel/SettingsPanel';

function App() {
  const { identificationDispatch } = useIdentificationContext();

  // Set identification numbers from query string parameters
  React.useEffect(() => {
    const getSessionId = async () => {
      try {
        await axios.get('https://qrmenumanagement.azurewebsites.net/api/Host/SessionStart/3');
        const response = await axios.get('https://qrmenumanagement.azurewebsites.net/api/Host/Tables/3');
        // console.log(response.data);
        identificationDispatch({type: 'SET_SESSION_ID', payload: response.data.tableSid});
      }
      catch (e) {
        console.log(e);
      }
    }

    ReactGA.initialize('UA-212161883-1');
    ReactGA.pageview(window.location.pathname);

    const parsedQueryString = queryString.parse(window.location.search);
    const restaurantId = parsedQueryString['restaurant-id']
    const sessionId = parsedQueryString['session-id'];
    if (!sessionId) {
      getSessionId();
    }
    
    identificationDispatch({type: 'SET_RESTAURANT_ID', payload: restaurantId});
    identificationDispatch({type: 'SET_SESSION_ID', payload: sessionId});
    identificationDispatch({type: 'SET_CUSTOMER_ID', payload: uuidv4()});
  }, [identificationDispatch]);

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="order" element={<Order />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="cancel" element={<PaymentCancel />} />
        <Route path="success" element={<PaymentSuccess />} />
      </Routes>
      
      <BottomNav />
      <ActionPanel />
      <LoadingScreen/>
      <SettingsPanel />
      <Navbar />
      <Alerts />
    </div>
  );
}

export default App;
