import './PaymentOptions.css';
import React from 'react';

const paymentOptions = [
  {
    id: 'stripe',
    name: 'Stripe',
    icon: 'fab fa-cc-stripe',
  },
  {
    id: 'card',
    name: 'Card',
    icon: 'fas fa-credit-card',
  },
  {
    id: 'cash',
    name: 'Cash',
    icon: 'fas fa-money-bill',
  },
]

const PaymentOptions = ({onSelect}) => {
  const [paymentType, setPaymentType] = React.useState('stripe');

  const handleSelect = (id) => {
    setPaymentType(id);
    onSelect?.(id);
  }

  const renderPaymentOptions = () => {
    const elements = [];
    paymentOptions.forEach(option => {
      elements.push(
        <div
          key={option.id}
          className={`payment-option ${paymentType === option.id ? 'payment-selected' : ''}`}
          onClick={() => {handleSelect(option.id)}}
        >
          <i className={option.icon}></i>
          <p>{option.name}</p>
        </div>
      );
    });
    return elements;
  }

  return (
    <div className="payment">
      <div className="payment-options">
        { renderPaymentOptions() }
      </div>
    </div>
  );
}

export default PaymentOptions;
