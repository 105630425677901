import './InteractiveMenuItemPopup.css';

// Libraries
import { AnimatePresence, motion } from 'framer-motion';

const InteractiveMenuItemPopup = ({item, currencySymbol, show, onFinish}) => {
  return (
    <AnimatePresence>
      {
        show && <motion.div
          className="interactive-menu-item-popup"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="interactive-menu-item-popup-shadow" onClick={onFinish} />
          <div className="interactive-menu-item-popup-contents">
            <h2 className="interactive-menu-item-popup-title">{item['Name'] || "Name"}</h2>
            <p className="interactive-menu-item-popup-description">{item['Description'] || "Description"}</p>
            <p className="interactive-menu-item-popup-price">{`${currencySymbol}${item['Price']}` || "?"}</p>
          </div>
        </motion.div>
      }
    </ AnimatePresence>
  );
}

export default InteractiveMenuItemPopup;
