export const setMenu = (newState, payload) => {
  newState.menu = payload;
}

export const setMenuFetched = (newState, payload) => {
  newState.isMenuFetched = payload;
}

export const setMenuLoaded = (newState, payload) => {
  newState.isMenuLoaded = payload;
}

export const addItem = (newState, payload) => {
  const addedItem = newState.addedItems.find(e => e.item['Id'] === payload['Id']);
  if (addedItem) {
    addedItem.amount++;
  }
  else {
    newState.addedItems.push({item: payload, amount: 1});
  }
}

export const subtractItem = (newState, payload) => {
  const addedItem = newState.addedItems.find(e => e.item['Id'] === payload['Id']);
  if (addedItem) {
    addedItem.amount--;
    if (addedItem.amount <= 0) {
      newState.addedItems = newState.addedItems.filter(e => e.item['Id'] !== payload['Id']);
    }
  }
}

export const resetItems = (newState) => {
  newState.addedItems = [];
}
