import './Bill.css';
import React from 'react';

// Libraries
import axios from 'axios';

// Contexts
import { useAlertsContext } from '../../contexts/alerts/alertsContext';
import { useIdentificationContext } from '../../contexts/identification/identificationContext';

const Bill = ({customerId, items, paymentOption}) => {
  const { alertsDispatch } = useAlertsContext();
  const { identificationState } = useIdentificationContext();
  const [price, setPrice] = React.useState(0);
  const [isPaying, setIsPaying] = React.useState(false);

  // Get price
  React.useEffect(() => {
    const getPrice = async () => {
      let price = 0;
      try {
        const response = await axios.get(
          `https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/Billing/${identificationState.sessionId}/${customerId}`);
        price = response.data;
      }
      catch (e) {
        console.log(e);
      }
      setPrice(price);
    }
    getPrice();
  });

  const renderItems = () => {
    const itemElements = [];
    items.forEach((item, index) => {
      itemElements.push(
        <div key={index} className="bill-item">
          <p>{item.name} x{item.quantity}</p><p>${item.quantity*item.price}</p>
        </div>
      );
    });
    return itemElements;
  }

  const handlePayment = async () => {
    if (isPaying) {
      try {
        await axios.post(
          `https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/endPayment/${identificationState.sessionId}`);
        setIsPaying(false);
      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      try {
        await axios.post(
          `https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/startPayment/${identificationState.sessionId}`);
        const response = await axios.post(
          `https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/StripeCreate/${price}`);
        setIsPaying(true);
        console.log(response.data);
        window.open(response.data);
      }
      catch (e) {
        alertsDispatch({type: 'QUEUE_ALERT', payload: 'Payment error: Someone else is currently paying.'});
      }
    }
  }

  return (
    <div className="bill">
      <div className="bill-items">
        { renderItems() }
      </div>
      <input
        className="bill-button"
        type="button"
        value={paymentOption === 'stripe' ? (isPaying ? 'Finish Payment' : `Pay $${price.toFixed(2)}`) : 'Check Payment Status'}
        onClick={handlePayment}
      />
    </div>
  );
}

export default Bill;
