import React from 'react';
import './Navbar.css';

// Libraries
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Contexts
import { useSettingsContext } from '../../contexts/settings/settingsContext';

// Components
import Logo from '../Logo/Logo';

const settingsToggleIconVariants = {
  open: {
    rotate: 180,
  },
  closed: {
    rotate: 0,
  },
}

const Navbar = () => {
  const { settingsState, settingsDispatch } = useSettingsContext();
  
  const handleSettingsToggle = () => {
    settingsDispatch({type: 'TOGGLE_SETTINGS_PANEL'});
  }

  return (
    <div className="navbar">
      {/* <div className="navbar-title"> */}
        <Link className="navbar-title" to="/">
          <div className="navbar-logo">
            <Logo className="navbar-logo" size="small"/>
          </div>
          <h1>QR Menu</h1>
        </Link>
      {/* </div> */}
      <motion.i
        className="fas fa-cog"
        onClick={handleSettingsToggle}
        variants={settingsToggleIconVariants}
        initial={'closed'}
        animate={settingsState.isOpen ? 'open' : 'closed'}
        transition={{
          type: 'spring',
          bounce: 0.3,
          duration: 0.5,
        }}
      />
    </div>
  );
}

export default Navbar;
