/* Reducers */
import {
  addAlert,
  dequeueAlert,
  removeAlert,
  queueAlert,
} from './alertsReducers.js';

const alertsReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'QUEUE_ALERT':
      queueAlert(newState, action.payload);
      break;
    case 'DEQUEUE_ALERT':
      dequeueAlert(newState, action.payload);
      break;
    case 'ADD_ALERT':
      addAlert(newState, action.payload);
      break;
    case 'REMOVE_ALERT':
      removeAlert(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default alertsReducer;
