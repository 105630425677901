import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './loadingScreenState.js';

/* Reducer */
import loadingScreenReducer from './loadingScreenReducer';

const LoadingScreenContext = createContext();

export const LoadingScreenProvider = (props) => {
  const [loadingScreenState, loadingScreenDispatch] = useReducer(loadingScreenReducer, initialState);

  return (
    <LoadingScreenContext.Provider value={{loadingScreenState, loadingScreenDispatch}}>
      {props.children}
    </LoadingScreenContext.Provider>
  )
}

export const useLoadingScreenContext = () => useContext(LoadingScreenContext);
