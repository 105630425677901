import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './uiStateState.js';

/* Reducer */
import uiStateReducer from './uiStateReducer';

const UiStateContext = createContext();

export const UiStateProvider = (props) => {
  const [uiState, uiStateDispatch] = useReducer(uiStateReducer, initialState);

  return (
    <UiStateContext.Provider value={{uiState, uiStateDispatch}}>
      {props.children}
    </UiStateContext.Provider>
  )
}

export const useUiStateContext = () => useContext(UiStateContext);
