/* Reducers */
import {
  toggleSettingsPanel,
} from './settingsReducers.js';

const settingsReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "TOGGLE_SETTINGS_PANEL":
      toggleSettingsPanel(newState);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default settingsReducer;
