import React from 'react';
import './InteractiveMenuItem.css';

// Contexts
import { useMenuContext } from "../../contexts/menu/menuContext";

// Components
import InteractiveMenuItemPopup from '../InteractiveMenuItemPopup/InteractiveMenuItemPopup';

const InteractiveMenuItem = ({item, currencySymbol}) => {
  const {menuState, menuDispatch} = useMenuContext();
  const [showAdvancedDetails, setShowAdvancedDetails] = React.useState(false);

  const handleAddAmount = () => {
    menuDispatch({type: 'ADD_ITEM', payload: item});
  }

  const handleSubtractAmount = () => {
    menuDispatch({type: 'SUBTRACT_ITEM', payload: item});
  }

  const toggleShowAdvancedDetails = () => {
    setShowAdvancedDetails(!showAdvancedDetails);
  }

  return (
    <div className="interactive-menu-item">
      <InteractiveMenuItemPopup
        item={item}
        currencySymbol={currencySymbol}
        show={showAdvancedDetails}
        onFinish={toggleShowAdvancedDetails}
      />
      <h4
        className="interactive-menu-item-name"
        onClick={toggleShowAdvancedDetails}
      >{item['Name'] || "Name"}</h4>
      <p
        className="interactive-menu-item-description"
        onClick={toggleShowAdvancedDetails}
      >{item['Description'] || "Description"}</p>
      <p className="interactive-menu-item-price">{`${currencySymbol}${item['Price']}` || "?"}</p>
      <div className="interactive-menu-item-amount">
        <div onClick={handleAddAmount}><i className="fas fa-plus" /></div>
        <p>{menuState.addedItems.find(e => e.item['Id'] === item['Id'])?.amount || 0}</p>
        <div onClick={handleSubtractAmount}><i className="fas fa-minus" /></div>
      </div>
    </div>
  );
}

export default InteractiveMenuItem;
