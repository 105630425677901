import React from 'react';
import './ActionPanel.css';

// Libraries
import axios from 'axios';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga';

// Contexts
import { useUiStateContext } from '../../contexts/uiState/uiStateContext';

// Components
import ActionItem from '../ActionItem/ActionItem';

// Fallback
import mockActions from './mockActions.json';

const actionPanelVariants = {
  open: { y: '32px' },
  // closed: { y: 'calc(100% - 32px)' },
  closed: { y: '100%' },
}

const actionPanelArrowVariants = {
  open: { rotate: 180, },
  closed: { rotate: 0, },
}

const actionPanelShadowVariants = {
  open: {opacity: 1, display: 'block' },
  closed: { opacity: 0, transitionEnd: {display: 'none'} },
}

const ActionPanel = () => {
  const { uiState, uiStateDispatch } = useUiStateContext();
  // const [uiState.actionPanelOpen, setIsOpen] = React.useState(false);
  const [actions, setActions] = React.useState([]);

  // Fetch actions using a GET request upon initial load.
  React.useEffect(() => {
    const getActions = async () => {
      let actions = null;
      try {
        const response = await axios.get('https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/Actions');
        actions = response.data;
      }
      catch (e) {
        console.log(e);
        actions = mockActions;
      }
      setActions(actions);
    }
    getActions();
  }, []);

  const handleActionPanelToggle = () => {
    uiStateDispatch({type: 'TOGGLE_ACTION_PANEL'});
    // setIsOpen(!uiState.actionPanelOpen);
  }

  // Sends a POST request for the action performed.
  const handleAction = async (actionType) => {
    ReactGA.event({
      category: 'Action Panel',
      action: actionType,
    });
    try {
      await axios.post(
        'https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/TriggeredAction',
        {
          'RestaurantId': 'mock-restaurant-id',
          'SessionId': 'mock-session-id',
          'TableId': 1,
          'Reason': actionType,
        }
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  // Returns the buttons corresponding to each action in JSX.
  const renderActions = () => {
    const actionItems = [];
    actions.forEach((action) => {
      actionItems.push(
        <ActionItem
          key={action.id}
          actionHandler={() => handleAction(action.id)}
          actionName={action.name}
          iconName={action.icon}
        />
      );
    });
    return actionItems;
  }

  return (
    <div className="action-panel">
      <motion.div
        className="action-panel-shadow"
        onClick={handleActionPanelToggle}
        variants={actionPanelShadowVariants}
        initial={'closed'}
        animate={uiState.actionPanelOpen ? 'open' : 'closed'}
        transition={{
          duration: 0.2,
        }}
      />
      <motion.div
        className="action-panel-content"
        variants={actionPanelVariants}
        initial={'closed'}
        animate={uiState.actionPanelOpen ? 'open' : 'closed'}
        transition={{
          type: 'spring',
          bounce: 0.3,
          duration: 0.5,
        }}
      >
        <div className="action-panel-handle" onClick={handleActionPanelToggle}>
          <motion.i
            className="fas fa-chevron-up"
            variants={actionPanelArrowVariants}
            initial={'closed'}
            animate={uiState.actionPanelOpen ? 'open' : 'closed'}
            transition={{
              duration: 0.2,
            }}
          />
        </div>
        <div className="action-panel-items">
          { renderActions() }
        </div>
      </motion.div>
    </div>
  );
}

export default ActionPanel;
