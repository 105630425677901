/* Reducers */
import {
  setProgress,
  setVisibility,
} from './loadingScreenReducers.js';

const loadingScreenReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "SET_PROGRESS":
      setProgress(newState, action.payload);
      break;
    case "SET_VISIBILITY":
      setVisibility(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default loadingScreenReducer;
