/* Reducers */
import { setCustomerId, setRestaurantId, setSessionId } from './identificationReducers.js';

const identificationReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'SET_RESTAURANT_ID':
      setRestaurantId(newState, action.payload);
      break;
    case 'SET_SESSION_ID':
      setSessionId(newState, action.payload);
      break;
    case 'SET_CUSTOMER_ID':
      setCustomerId(newState, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default identificationReducer;
