import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './menuState.js';

/* Reducer */
import menuReducer from './menuReducer';

const MenuContext = createContext();

export const MenuProvider = (props) => {
  const [menuState, menuDispatch] = useReducer(menuReducer, initialState);

  return (
    <MenuContext.Provider value={{menuState, menuDispatch}}>
      {props.children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = () => useContext(MenuContext);
