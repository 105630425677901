import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './alertsState.js';

/* Reducer */
import alertsReducer from './alertsReducer';

const AlertsContext = createContext();

export const AlertsProvider = (props) => {
  const [alertsState, alertsDispatch] = useReducer(alertsReducer, initialState);

  return (
    <AlertsContext.Provider value={{alertsState, alertsDispatch}}>
      {props.children}
    </AlertsContext.Provider>
  )
}

export const useAlertsContext = () => useContext(AlertsContext);
