import './Logo.css';

const Logo = ({size}) => {
  return (
    <div className={size === 'small' ? 'logo-small' : 'logo'}>
      <div className={size === 'small' ? 'logo-center-small' : 'logo-center'}/>
    </div>
  );
}

export default Logo;
