import React from 'react';
import './Menu.css';

// Libraries
import axios from 'axios';

// Contexts
import { useLoadingScreenContext } from '../../contexts/loadingScreen/loadingScreenContext';
import { useMenuContext } from "../../contexts/menu/menuContext";

// Components
import InteractiveMenu from '../InteractiveMenu/InteractiveMenu';
import PDFMenu from '../PDFMenu/PDFMenu';

// Fallbacks
import mockInteractiveMenu from './mockInteractiveMenu.json';

const Menu = () => {  // TODO: Move loading screen functionality into MenuLoadingScreen.
  const {loadingScreenDispatch} = useLoadingScreenContext();
  const {menuState, menuDispatch} = useMenuContext();
  const menuContainer = React.useRef();

  // Fetch menu upon initial mount.
  React.useEffect(() => {
    const getMenu = async () => {
      let menu = {type: null, payload: null};
      try {
        const response = await axios.get(
          'https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/MenuItems',
          {
            headers: {
              'restaurant-id': '[insert id here]',
              'table-sid': '[insert id here]',
            }
          }
        );
        menu = response.data === 'No Active Menu' ? mockInteractiveMenu : response.data;
      }
      catch (e) {
        console.log(e);
        menu = mockInteractiveMenu;
      }
      menuDispatch({type: 'SET_MENU', payload: menu});
      menuDispatch({type: 'SET_MENU_FETCHED', payload: true});
    }
  
    if (!menuState.isMenuFetched) { getMenu(); }

    return () => {
      loadingScreenDispatch({type: 'SET_PROGRESS', payload: 0});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuLoadProgress = (progress) => {
    loadingScreenDispatch({type: 'SET_PROGRESS', payload: progress});
  }

  const handleMenuLoadSuccess = () => {
    loadingScreenDispatch({type: 'SET_PROGRESS', payload: 100});
    loadingScreenDispatch({type: 'SET_VISIBILITY', payload: false});
    menuDispatch({type: 'SET_MENU_LOADED', payload: true});
  }

  const renderMenu = () => {
    switch (menuState.menu?.type) {
      case 'pdf':
        return (
          <PDFMenu
            menuContainer={menuContainer}
            file={menuState.menu.payload}
            onLoadProgress={handleMenuLoadProgress}
            onLoadSuccess={handleMenuLoadSuccess}
          />
        );
      case 'interactive':
        return (
          <InteractiveMenu
            name={menuState.menu.payload.name}
            items={menuState.menu.payload.items}
            currencySymbol={menuState.menu.payload.currencySymbol}
            onLoadProgress={handleMenuLoadProgress}
            onLoadSuccess={handleMenuLoadSuccess}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="menu" ref={menuContainer}>
      { renderMenu() }
    </div>
  );
}

export default Menu;
