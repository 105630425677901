import React from 'react';
import './SettingsPanel.css';

// Libraries
import { motion } from 'framer-motion';

// Contexts
import { useAlertsContext } from '../../contexts/alerts/alertsContext';
import { useIdentificationContext } from '../../contexts/identification/identificationContext';
import { useMenuContext } from '../../contexts/menu/menuContext';
import { useSettingsContext } from '../../contexts/settings/settingsContext';

const settingPanelVariants = {
  open: { x: 0, opacity: 1, display: 'block' },
  closed: { x: 100, opacity: 0, transitionEnd: { display: 'none' } }
}

const settingPanelShadowVariants = {
  open: { opacity: 1, display: 'block' },
  closed: { opacity: 0, transitionEnd: { display: 'none' } },
}

const SettingsPanel = () => {
  const { alertsDispatch } = useAlertsContext();
  const { identificationState } = useIdentificationContext();
  const { menuState } = useMenuContext();
  const { settingsState, settingsDispatch } = useSettingsContext();

  const handleSettingsPanelToggle = () => {
    settingsDispatch({type: 'TOGGLE_SETTINGS_PANEL'});
  }

  const sendAlert = () => {
    alertsDispatch({type: 'QUEUE_ALERT', payload: 'Test Alert'});
  }

  const logMenu = () => {
    console.log(menuState);
  }

  return (
    <div className="settings-panel">
      <motion.div
        className="settings-panel-shadow"
        onClick={handleSettingsPanelToggle}
        variants={settingPanelShadowVariants}
        initial={'closed'}
        animate={settingsState.isOpen ? 'open' : 'closed'}
        transition={{
          type: 'tween',
          duration: 0.3,
        }}
      />
      <motion.div
        className="settings-panel-content"
        variants={settingPanelVariants}
        initial={'closed'}
        animate={settingsState.isOpen ? 'open' : 'closed'}
        transition={{
          type: 'tween',
          duration: 0.3,
        }}
      >
        {/* <div className="settings-panel-close"><i className="fas fa-times" /></div> */}
        <p><b>Stats for nerds</b></p>
        <p>Restaurant ID: {identificationState.restaurantId || "None"}</p>
        <p>Session ID: {identificationState.sessionId || "None"}</p>
        <p>Customer ID: {identificationState.customerId || "None"}</p>
        <hr />
        <input type="button" value="Send Alert" onClick={sendAlert} />
        <hr />
        <input type="button" value="Log Menu" onClick={logMenu} />
      </motion.div>
    </div>
  );
}

export default SettingsPanel;
