import React, { createContext, useContext, useReducer } from 'react';

/* State */
import initialState from './identificationState.js';

/* Reducer */
import identificationReducer from './identificationReducer';

const IdentificationContext = createContext();

export const IdentificationProvider = (props) => {
  const [identificationState, identificationDispatch] = useReducer(identificationReducer, initialState);

  return (
    <IdentificationContext.Provider value={{identificationState, identificationDispatch}}>
      {props.children}
    </IdentificationContext.Provider>
  )
}

export const useIdentificationContext = () => useContext(IdentificationContext);
