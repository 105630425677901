import './Order.css';

// Libraries
import axios from 'axios';
import ReactGA from 'react-ga';

// Contexts
import { useAlertsContext } from '../../contexts/alerts/alertsContext';
import { useIdentificationContext } from '../../contexts/identification/identificationContext';
import { useMenuContext } from '../../contexts/menu/menuContext';

// Components
import Page from '../../components/Page/Page';

const Order = () => {
  const { alertsDispatch } = useAlertsContext();
  const { identificationState } = useIdentificationContext();
  const { menuState, menuDispatch } = useMenuContext();

  const handleSendOrder = async () => {
    ReactGA.event({
      category: 'UI',
      action: 'Send Order',
    });

    const orderList = [];
    menuState.addedItems.forEach(item => {
      orderList.push({
        'foodId': item.item['Id'],
        'quantity': item.amount,
        'customerId': identificationState.customerId,
      });
    });
    if (orderList.length === 0) {
      return;
    }

    try {
      const response = await axios.post(
        'https://qrmenumanagement.azurewebsites.net/api/CustomerRequests/Orders',
        {
          "sessionId": identificationState.sessionId,
          "orderList": orderList,
        }
      );
      if (response.status === 200) {
        alertsDispatch({type: 'QUEUE_ALERT', payload: 'Order successfully sent to kitchen.'});
        menuDispatch({type: 'RESET_ITEMS'});
      }
    }
    catch (e) {
      alertsDispatch({type: 'QUEUE_ALERT', payload: 'Uh oh! Something went wrong with the order.'});
      console.log(e);
    }
  }

  const renderAddedItems = () => {
    const addedItems = [];
    menuState.addedItems.forEach(item => {
      addedItems.push(
        <div key={item.item['Id']} className="order-page-item">
          <p>{item.item['Name']}</p><p>x{item.amount}</p>
        </div>
      );
    });
    return addedItems.length === 0 ?
    <p>No items added. Please add food items and check back after.</p> :
    addedItems;
  }

  return (
    <Page>
      <h2 className="order-page-title">Review Order</h2>
      <div className="order-page-items">
        { renderAddedItems() }
      </div>
      <div className="order-button" onClick={handleSendOrder}>Send Order</div>
    </Page>
  );
}

export default Order;
