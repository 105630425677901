// Libraries
import { v4 as uuidv4 } from 'uuid';

export const queueAlert = (newState, payload) => {
  newState.alertsQueue.push({
    id: uuidv4(),
    value: payload,
  });
}

export const dequeueAlert = (newState, payload) => {
  newState.alertsQueue = newState.alertsQueue.filter(e => e.id !== payload);
}

export const addAlert = (newState, payload) => {
  newState.alerts.push(payload);
}

export const removeAlert = (newState, payload) => {
  newState.alerts = newState.alerts.filter(e => e.id !== payload);
}
