import './Survey.css';

// Libraries
import { AnimatePresence, motion } from 'framer-motion';
import ReactGA from 'react-ga';

const Survey = ({show, onFinish}) => {
  const handleSubmit = (rating) => {
    ReactGA.event({
      category: 'survey',
      action: rating,
    });
    onFinish();
  }

  return (
    <AnimatePresence>
      {
        show && <motion.div
          className="survey"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="survey-shadow" onClick={() => handleSubmit('No Rating')} />
          <div className="survey-contents">
            <h2 className="survey-title">How was your dining experience?</h2>
            <div className="survey-buttons">
              <p className="survey-button" onClick={() => handleSubmit('Amazing')}>Amazing</p>
              <p className="survey-button" onClick={() => handleSubmit('Good')}>Good</p>
              <p className="survey-button" onClick={() => handleSubmit('So-so')}>So-so</p>
              <p className="survey-button" onClick={() => handleSubmit('Bad')}>Bad</p>
              <p className="survey-button" onClick={() => handleSubmit('Terrible')}>Terrible</p>
            </div>
          </div>
        </motion.div>
      }
    </ AnimatePresence>
  );
}

export default Survey;
