import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// Libraries
import { BrowserRouter } from 'react-router-dom';

// Contexts
import { AlertsProvider } from './contexts/alerts/alertsContext';
import { IdentificationProvider } from './contexts/identification/identificationContext';
import { LoadingScreenProvider } from './contexts/loadingScreen/loadingScreenContext';
import { MenuProvider } from './contexts/menu/menuContext';
import { SettingsProvider } from './contexts/settings/settingsContext';
import { UiStateProvider } from './contexts/uiState/uiStateContext';

// Components
import App from './App';

// Others
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <AlertsProvider>
    <IdentificationProvider>
    <LoadingScreenProvider>
    <MenuProvider>
    <SettingsProvider>
    <UiStateProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </UiStateProvider>
    </SettingsProvider>
    </MenuProvider>
    </LoadingScreenProvider>
    </IdentificationProvider>
    </AlertsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
