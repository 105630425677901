import './LoadingScreen.css';

// Libraries
import { AnimatePresence, motion } from 'framer-motion';

// Contexts
import { useLoadingScreenContext } from '../../contexts/loadingScreen/loadingScreenContext';

// Components
import Logo from '../Logo/Logo';

const LoadingScreen = () => {
  const { loadingScreenState } = useLoadingScreenContext();

  return (
    <AnimatePresence>
      {
        loadingScreenState.visible && <motion.div
          className="loading-screen"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <motion.div
            animate={{ rotate: 360 }}
            transition={{
              type: 'tween',
              ease: 'linear',
              duration: 2,
              repeat: Infinity,
            }}
          ><Logo /></motion.div>
          <p>Loading... {loadingScreenState.progress}%</p>
        </motion.div>
      }
    </AnimatePresence>
  );
}

export default LoadingScreen;
