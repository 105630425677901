/* Reducers */
import {
  setMenu,
  setMenuFetched,
  setMenuLoaded,
  addItem,
  subtractItem,
  resetItems,
} from './menuReducers.js';

const menuReducer = (state, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "SET_MENU":
      setMenu(newState, action.payload);
      break;
    case "SET_MENU_FETCHED":
      setMenuFetched(newState, action.payload);
      break;
    case "SET_MENU_LOADED":
      setMenuLoaded(newState, action.payload);
      break;
    case "ADD_ITEM":
      addItem(newState, action.payload);
      break;
    case "SUBTRACT_ITEM":
      subtractItem(newState, action.payload);
      break;
    case "RESET_ITEMS":
      resetItems(newState);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default menuReducer;
